import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
// import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";

import Script from "next/script";
import { SessionProvider } from "next-auth/react";


function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>

      <Script id="Adsense-id" data-ad-client="ca-pub-9620312339524274"
        async strategy="afterInteractive"
        onError={ (e) => { console.error('Script failed to load', e) }}
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
  
      <Meta title="Home 1" />

      <SessionProvider session={session}>
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
            <UserContext.Provider value={{ scrollRef: scrollRef }}>
              {pid === "/login" ? (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
                  ) : (
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              )}
            </UserContext.Provider>
        </ThemeProvider>
      </Provider>
      </SessionProvider>

    </>
  );
}

export default MyApp;
