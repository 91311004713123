import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/logo.png";
import WhiteLogo from "./../../public/images/logo_white.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEnglish, setKorean } from '../../redux/counterSlice';
import { useSession } from "next-auth/react";
import { signOut } from "next-auth/react";


export default function Header01() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(1); // it means open
  const englishLanguage = useSelector((state) => state.counter.englishLanguage);
  const session = useSession();

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: true?"Tools":"툴",
    pages: [
      {
        id: uuidv4(),
        name: englishLanguage?"Bitcoin Gift":"Bitcoin Gift",
        path: englishLanguage?"/gift/":"/gift",
      },
      {
        id: uuidv4(),
        name: englishLanguage?"Bitcoin P2P":"Bitcoin P2P",
        path: englishLanguage?"/p2p/":"/p2p",
      },
      // {
      //   id: uuidv4(),
      //   name: englishLanguage?"복리계산기":"복리계산기",
      //   path: englishLanguage?"https://calc.yanabu.com/":"https://calc.yanabu.com/",
      // },
      // {
      //   id: uuidv4(),
      //   name: englishLanguage?"Timestamp":"시간변환기",
      //   path: englishLanguage?"/timestamp/en":"/timestamp",
      // },

    ],
  };

  const game = {
    id: 1,
    name: "Game",
    pages: [
      {        
        id: uuidv4(),
        name: "게임",
        path: "/game",
      }
    ],
  };

  
  

  const mobileCollapse = (id) => {
    console.log('mobileCollapse', id)
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div className="flex items-center px-6 py-6 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <a>
              <div className="dark:hidden">
                <Image
                  src={Logo}
                  height={28}
                  width={130}
                  alt=""
                  className="max-h-7 h-auto "
                />
              </div>
              <div className="hidden dark:block">
                <Image
                  src={WhiteLogo}
                  height={28}
                  width={130}
                  alt=""
                />
              </div>
            </a>
          </Link>
          {/* End  logo */}


          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* home */}
                <li className="js-nav-dropdown group relative">
                  <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                    <span
                      className={
                        isParentPageActive(home.pages, route.asPath)
                          ? "text-accent  dark:text-accent"
                          : ""
                      }
                    >
                    {true?"Tools":"툴"}
                    </span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  <ul className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative">
                    {home?.pages?.map((page) => (
                      <li key={page.id}>
                        {page.name === '복리계산기' ? (
                          <a className="dark:hover:bg-jacarta-600  hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between " href={page.path} target="_blank" rel="noopener noreferrer">{page.name}</a>
                        ) : (
                          <Link href={page.path}>
                          <a className="dark:hover:bg-jacarta-600  hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between ">
                            <span
                              className={`font-display ${
                                isChildrenPageActive(page.path, route.asPath)
                                  ? "text-accent dark:text-accent"
                                  : "text-jacarta-700"
                              } text-sm dark:text-white`}
                            >
                              {page.name}
                            </span>
                            {page.condition ? (
                              <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                                new
                              </span>
                            ) : undefined}
                          </a>
                        </Link>
                        )}
                      </li>



                    ))}
                  </ul>
                </li>

                {/* create */}
                <li className="group">
                  <Link href="/reward">
                    <a>
                      <button className="font-display dark:hover:text-accent hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/reward")
                              ? "text-accent dark:text-accent"
                              : ""
                          }
                        >
                          Reward
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>

                {/* create */}
                <li className="group">
                  <Link href="/trading">
                    <a>
                      <button className="font-display dark:hover:text-accent hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:text-white lg:px-5">
                        <span
                          className={
                            isChildrenPageActive(route.asPath, "/trading")
                              ? "text-accent dark:text-accent"
                              : ""
                          }
                        >
                          Trading
                        </span>
                      </button>
                    </a>
                  </Link>
                </li>



              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className="ml-8 hidden items-center lg:flex xl:ml-12">
              
              <button className="rounded-lg s-dark-mode-trigger dark:bg-accent group ml-2 flex h-10 w-20 items-center justify-center  border border-jacarta-100 bg-white transition-colors dark:border-transparent dark:bg-white/[.15] font-display text-sm font-semibold hover:border-transparent dark:hover:border-jacarta-100"
              onClick={()=>{
                if(session && session.data) {
                  // logout
                  signOut();
                }
                else {
                  route.push('/login')
                }
              }}
              >
              {session && session.data ? 'logout':'login'}
            </button>
              <DarkMode />
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">

              <button
                onClick={()=>{
                  if(session && session.data) {
                    // logout
                    signOut();
                  }
                  else {
                    route.push('/login')
                  }
                }}
              >
              <a
                className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                aria-label="profile"
              >
              {(session && session.data) ?
              <svg 
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
              <path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/>
              </svg>
              :
              <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
            </svg>
              }
              </a>
            </button>
            <DarkMode />
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}
              alt=""
              className="max-h-7 h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt=""
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          </span>
        </form>

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row">
            <li className="js-nav-dropdown group relative">
              <button
                onClick={() => mobileCollapse(home.id)}
                className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full"
              >
                <span
                  className={
                    isParentPageActive(home.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {home.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>

              <ul
                className={`dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === home.id ? "block" : "hidden"
                }`}
              >
                {home?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link href={page.path}>
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between">
                        <span
                          className={`font-display ${
                            isChildrenPageActive(route.asPath, page.path)
                              ? "text-accent dark:text-accent"
                              : "text-jacarta-700"
                          } text-sm dark:text-white`}
                        >
                          {page.name}
                        </span>
                        {page.condition ? (
                          <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                            new
                          </span>
                        ) : undefined}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/reward">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/reward", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      reward
                    </span>
                  </button>
                </a>
              </Link>
            </li>

            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/trading">
                <a>
                  <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                    <span
                      className={
                        isChildrenPageActive("/trading", route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      trading
                    </span>
                  </button>
                </a>
              </Link>
            </li>            

            
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
