import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta name="viewport" content="initial-scale=1.0, user-scalable=no, maximum-scale=1, width=device-width" />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "야나부 툴",
  keyword:
    "복리계산기, 디스코드 시간 변경, 타임스탬프 변환, 야나부",
  desc: "야나부 툴입니다. 온라인으로 간편하게 사용할 수 있는 툴을 제공합니다.",
};

export default Meta;
